<template>
  <div class="order-summary">
    <!-- Summary Table -->
    <table class="summary-table">
      <thead>
        <tr>
          <th>Toplam Sipariş</th>
          <th>Toplam Net Satış</th>
          <th>Toplam Maliyet</th>
          <th>Toplam Komisyon</th>
          <th>Toplam Kargo</th>
          <th>Toplam Platform</th>
          <th>Toplam KDV</th>
          <th>Toplam Kar/Zarar</th>
          <th>Kar/Zarar (KDV Hariç)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ totalOrders }}</td>
          <td>{{ formatCurrency(totalSales) }} {{ orders[Object.keys(orders)[0]].currencyCode }}</td>
          <td>{{ formatCurrency(totalCost) }} TRY</td>
          <td>{{ formatCurrency(totalCommissions) }} TRY</td>
          <td>{{ formatCurrency(totalCargoFee) }} TRY</td>
          <td>{{ formatCurrency(totalPlatformFee) }} TRY</td>
          <td>{{ formatCurrency(totalVAT) }} TRY</td>
          <td>{{ calculateTotalProfit() }} TRY</td>
          <td>{{ calculateTotalProfitNoVAT() }} TRY</td>
        </tr>
      </tbody>
    </table>

    <div class="filter-section">
      <div class="date-filters">
        <label for="start-date">Başlangıç Tarihi:</label>
        <input type="date" id="start-date" v-model="filter.startDate" @change="applyDateFilter" />
        
        <label for="end-date">Bitiş Tarihi:</label>
        <input type="date" id="end-date" v-model="filter.endDate" @change="applyDateFilter" />

        <label for="barcode">Barkod Ara:</label>
        <input type="text" id="barcode" v-model="filter.barcode" @input="applyBarcodeFilter" placeholder="Barkod girin" />
      </div>
    </div>

    <div class="most-sold-products">
      <h5 class="header">En Çok Satılan Ürünler</h5>
      
      <div class="table-container">
        <table class="modern-table">
          <thead>
            <tr>
              <th>Ürün Adı</th>
              <th>Barkod</th>
              <th>Adet</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="product in getPagedProducts()" :key="product.productName">
              <td>{{ product.productName }}</td>
              <td>{{ product.barcode }}</td>
              <td>{{ product.totalQuantitySold }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Sayfalama -->
      <div class="pagination">
        <button 
          v-for="page in Math.ceil(mostSoldProducts.length / productsPerPage)" 
          :key="page"
          @click="changeProductsPage(page)"
          :class="['pagination-btn', { active: currentProductsPage === page }]"
        >
          {{ page }}
        </button>
      </div>
    </div>

    <div class="most-sold-products">
      <h5 class="header">En Çok Kâr Eden Ürünler</h5>
      <div class="table-container">
        <table class="modern-table">
          <thead>
            <tr>
              <th>Ürün Adı</th>
              <th>Barkod</th>
              <th>Kâr (TRY)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="product in getPagedProfitProducts()" :key="product.barcode">
              <td>{{ product.name }}</td>
              <td>{{ product.barcode }}</td>
              <td>{{ product.profit.toFixed(2) }} TRY</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="pagination">
        <button 
          class="pagination-btn" 
          @click="changeProfitPage(page)" 
          :class="{ active: page === currentProfitPage }" 
          v-for="page in Math.ceil(mostProfitableProducts.length / productsPerPage)" 
          :key="page">
          {{ page }}
        </button>
      </div>
    </div>

    <div v-for="(order, orderNumber) in filteredOrders" :key="orderNumber" class="order-item">
      <div class="order-header" @click="toggleOrderDetails(orderNumber)">
        <h4 
          :class="{
            'profit': calculateProfitLossPerOrderNoVAT(order) > 0,
            'loss': calculateProfitLossPerOrderNoVAT(order) < 0
          }">
          #{{ order.orderNumber }} | {{ formatDate(order.orderDate) }} | 
          {{ calculateProfitLossPerOrder(order) > 0 ? calculateProfitLossPerOrder(order).toFixed(2) + ' TL Kazanç (KDV dahil)' : Math.abs(calculateProfitLossPerOrder(order)).toFixed(2) + ' TL Zarar (KDV dahil)' }} | 
          {{ calculateProfitLossPerOrderNoVAT(order) > 0 ? calculateProfitLossPerOrderNoVAT(order).toFixed(2) + ' TL Kazanç (KDV hariç)' : Math.abs(calculateProfitLossPerOrderNoVAT(order)).toFixed(2) + ' TL Zarar (KDV hariç)' }}

        </h4>
      <button>{{ order.isOpen ? 'Hide Details' : 'Show Details' }}</button>
      </div>

      <div v-if="order.isOpen" class="order-details">
        <div class="left-side">
          <p><strong>Order ID:</strong> {{ order.id }}</p>
          <p><strong>Order Number:</strong> {{ order.orderNumber }}</p>
          <p><strong>Status:</strong> {{ order.status }}</p>
          <p><strong>Customer Name:</strong> {{ order.customerFirstName }} {{ order.customerLastName }}</p>
          <p><strong>Customer Email:</strong> {{ order.customerEmail }}</p>
          <p><strong>Delivery Address:</strong> {{ order.shipmentAddress.fullAddress }}</p>
          <p><strong>Invoice Address:</strong> {{ order.invoiceAddress.fullAddress }}</p>
          <p><strong>Agreed Delivery Date:</strong> {{ formatDate(order.agreedDeliveryDate) }}</p>
          <p><strong>Estimated Delivery Date:</strong> {{ formatDate(order.estimatedDeliveryEndDate) }}</p>
          <p><strong>Fast Delivery:</strong> {{ order.fastDelivery ? "Yes" : "No" }}</p>
          <p><strong>Cargo Provider Name:</strong> {{ order.cargoProviderName }}</p>
          <p><strong>Total Desi:</strong> {{ getTotalDesi(order) }}</p>
          <p><strong>Tracking Link:</strong> <a :href="order.cargoTrackingLink" target="_blank">Track Shipment</a></p>
          <p><strong>Tracking Number:</strong> {{ order.cargoTrackingNumber }}</p>
          <p><strong>Total Price:</strong> {{ order.totalPrice }} {{ order.currencyCode }}</p>
          <p><strong>Platform Fee:</strong> {{ order.platformFee }} TRY</p>
          <p><strong>Cargo Fee:</strong> {{ calculateCargoFee(order) }} TRY</p>
          <p><strong>KDV Tutarı:</strong> {{ calculateVAT(order) }} TRY</p>
          <p><strong>Profit/Loss:</strong> {{ calculateProfitLossPerOrder(order) }} TRY</p>
        </div>

        <div>
          <div class="right-side">
            <ul>
              <li v-for="(line, idx) in order.lines" :key="idx">
                <p><strong>Product:</strong> {{ line.productName }}</p>
                <p><strong>Barcode:</strong> {{ line.barcode }}</p>
                <p><strong>Price:</strong> {{ line.amount }} {{ line.currencyCode }}</p>
                <p><strong>Quantity:</strong> {{ line.quantity }}</p>
                <p><strong>Discount:</strong> {{ line.discount }} {{ line.currencyCode }}</p>
                <p><strong>Cost:</strong> {{ line.cost }} TRY</p>
                <p><strong>Total Commission:</strong> {{ calculateTotalCommission(order) }} TRY</p>
                <p><strong>Commission Rate:</strong> {{ calculateOrderCommissionRate(order) }}%</p>
              </li>
            </ul>
          </div>
          <div class="right-side">
            <h5>Package History:</h5>
            <ul>
              <li v-for="(history, idx) in order.packageHistories" :key="idx">
                <p><strong>Status:</strong> {{ history.status }}</p>
                <p><strong>Date:</strong> {{ formatDate(history.createdDate) }}</p>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccordionOrderDetails",
  data() {
    return {
      orders: {}, // API'den gelen veriler
      platformFee: 0, // Platform ücreti
      totalOrders: 0,
      totalSales: 0,
      totalDiscounts: 0,
      totalCost: 0,
      totalCommissions: 0,
      totalCargoFee: 0,
      totalPlatformFee: 0,
      totalVAT: 0,
      profitLoss: "",
      filteredOrders: {}, // Filtrelenmiş siparişler
      filter: {
        startDate: null, // Başlangıç tarihi
        endDate: null,   // Bitiş tarihi
        barcode: '',
      },
      productsPerPage: 5, // Sayfa başına ürün sayısı
      currentProductsPage: 1, // Başlangıç sayfası
      mostSoldProducts: [], // Hesaplanan ürün verileri
      mostProfitableProducts: [], // En çok kâr eden ürünler
      currentProfitPage: 1, // Aktif sayfa
      productsPerPage: 5, // Sayfa başına ürün
    };
  },
  created() {
    const ordersPromise = this.fetchData("https://kitpanel-33ba2-default-rtdb.firebaseio.com/ty_data.json", {
      method: "GET",
      headers: {
        Authorization: "Basic NW90RUFZTGlKY2JhS1pKWkg3N3I6M2xZZ1pIUVpKbnljUlduWHE4U3E=",
      },
    });
    const platformFeePromise = this.fetchData("https://kitpanel-33ba2-default-rtdb.firebaseio.com/costs/ty_platfrom_fee.json");
    const costComissionPromise = this.fetchData("https://kitpanel-33ba2-default-rtdb.firebaseio.com/costs/trendyol.json");

    // Yeni API isteği (bağımsız)
    this.fetchData("https://us-central1-kitpanel-proxy-server.cloudfunctions.net/api/fetch-orders", {
      method: "GET",
    }).catch((error) => {
      console.error("Fetch and report orders API error:", error);
    });

    Promise.all([ordersPromise, platformFeePromise, costComissionPromise])
      .then(([orders, platformFee, costComissionData]) => {
        this.orders = orders;
        this.filteredOrders = orders;
        this.platformFee = platformFee;
        this.costComissionData = costComissionData;

        // Siparişleri orderNumber'a göre azalan sırayla sıralıyoruz
        this.orders = Object.keys(orders)
          .sort((a, b) => b - a) // 'orderNumber'ı büyükten küçüğe sıralıyoruz
          .reduce((sortedOrders, key) => {
            sortedOrders[key] = orders[key];
            return sortedOrders;
          }, {});

        this.addPlatformFeeToOrders();
        this.matchCostComissionToOrder();
        this.calculateTotals();
        this.getMostSoldProducts();
        this.getMostProfitableProducts();
      })
      .catch(this.handleError);
  },
  methods: {
    applyBarcodeFilter() {
      // Barkod filtresi uygulanacak
      const barcodeFilter = this.filter.barcode.trim().toLowerCase();

      if (barcodeFilter) {
        // Tüm siparişleri gezip yalnızca barkod eşleşenleri filtrele
        this.filteredOrders = Object.values(this.orders).filter((order) =>
          order.lines.some((line) => line.barcode.toLowerCase() === barcodeFilter)
        );
      } else {
        // Eğer barkod arama yapılmazsa tüm siparişleri göster
        this.filteredOrders = Object.values(this.orders);
      }

      // Filtreleme sonrası toplamları yeniden hesapla
      this.addPlatformFeeToOrders();
      this.matchCostComissionToOrder();
      this.calculateTotals();
      this.getMostSoldProducts();
      this.getMostProfitableProducts();
    },
    getMostProfitableProducts() {
      const productMap = {};

      // Siparişlerden kâr hesaplama
      Object.values(this.filteredOrders).forEach((order) => {
        order.lines.forEach((line) => {
          const totalSales = this.calculateTotalSales(order); // Satış Geliri
          const totalCost = this.calculateTotalCost(order); // Maliyet
          const totalDiscount = this.calculateTotalDiscount(order); // İndirim
          const totalCommission = this.calculateTotalCommission(order); // Komisyon
          const shippingFee = this.calculateCargoFee(order); // Kargo Ücreti
          const vat = this.calculateVAT(order); // KDV
          const platformFee = this.platformFee; // Platform Ücreti

          // Kar/Zarar Hesaplama
          const profitOrLoss = totalSales - (totalCost + totalCommission + shippingFee + platformFee + vat + totalDiscount);

          // Ürün bazlı kâr hesaplama
          if (!productMap[line.barcode]) {
            productMap[line.barcode] = {
              name: line.productName,
              barcode: line.barcode,
              profit: 0,
            };
          }

          productMap[line.barcode].profit += profitOrLoss; // Ürünün toplam karını biriktir
        });
      });

      // Kâr miktarına göre sıralama (en kârlı ürünler önce gelir)
      this.mostProfitableProducts = Object.values(productMap).sort((a, b) => b.profit - a.profit);
    },
    getPagedProfitProducts() {
      const startIndex = (this.currentProfitPage - 1) * this.productsPerPage;
      const endIndex = startIndex + this.productsPerPage;
      return this.mostProfitableProducts.slice(startIndex, endIndex);
    },
    changeProfitPage(pageNumber) {
      this.currentProfitPage = pageNumber;
    },
    getMostSoldProducts() {
    const productMap = {};

    // Siparişlerden ürün verilerini işleme
    Object.values(this.filteredOrders).forEach(order => {
      order.lines.forEach(line => {
        if (!productMap[line.productName]) {
          productMap[line.productName] = {
            productName: line.productName,
            barcode: line.barcode,
            totalQuantitySold: 0,
          };
        }
        productMap[line.productName].totalQuantitySold += line.quantity;
      });
    });

    // Satışa göre sıralama
    this.mostSoldProducts = Object.values(productMap).sort((a, b) => b.totalQuantitySold - a.totalQuantitySold);
    },
    getPagedProducts() {
      const startIndex = (this.currentProductsPage - 1) * this.productsPerPage;
      const endIndex = startIndex + this.productsPerPage;
      return this.mostSoldProducts.slice(startIndex, endIndex);
    },
    changeProductsPage(pageNumber) {
      this.currentProductsPage = pageNumber;
    },
    applyDateFilter() {
      const getStartOfDay = (date) => {
        // Türkiye (İstanbul) saat diliminde günün başlangıcını al (00:00:00)
        const startDate = new Date(date);
        startDate.setHours(0, 0, 0, 0); // 00:00:00:000 ayarlıyoruz
        const timeZoneOffset = startDate.getTimezoneOffset() * 60000; // UTC'ye göre fark
        return startDate.getTime() - timeZoneOffset; // Epoch (milisaniye) cinsinden dönüştür
      };

      const getEndOfDay = (date) => {
        // Türkiye (İstanbul) saat diliminde günün sonunu al (23:59:59)
        const endDate = new Date(date);
        endDate.setHours(23, 59, 59, 999); // 23:59:59:999 ayarlıyoruz
        const timeZoneOffset = endDate.getTimezoneOffset() * 60000; // UTC'ye göre fark
        return endDate.getTime() - timeZoneOffset; // Epoch (milisaniye) cinsinden dönüştür
      };

      // Kullanıcıdan tarih aralığını al
      const startDateEpoch = this.filter.startDate ? getStartOfDay(this.filter.startDate) : null;
      const endDateEpoch = this.filter.endDate ? getEndOfDay(this.filter.endDate) : null;

      // Siparişleri filtreliyoruz
      this.filteredOrders = Object.values(this.orders).filter((order) => {
      let orderDateEpoch = order.orderDate; // Sipariş tarihi epoch formatında

        //if (orderDateEpoch) {
          // Sipariş tarihinden 3 saat çıkartıyoruz
          //orderDateEpoch -= 3 * 60 * 60 * 1000; // 3 saat = 3 * 60 dakika * 60 saniye * 1000 milisaniye
        //}

        if (startDateEpoch && endDateEpoch) {
          // Belirli tarih aralığında kontrol
          return orderDateEpoch >= startDateEpoch && orderDateEpoch <= endDateEpoch;
        } else if (startDateEpoch) {
          // Sadece başlangıç tarihine göre filtrele
          return orderDateEpoch >= startDateEpoch;
        } else if (endDateEpoch) {
          // Sadece bitiş tarihine göre filtrele
          return orderDateEpoch <= endDateEpoch;
        }
        return true; // Tüm siparişleri göster
      });

      this.calculateTotals();
      this.getMostSoldProducts();
      this.getMostProfitableProducts();
    },
    formatCurrency(amount) {
      if (typeof amount === 'number') {
        return amount.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      }
      return amount;
    },
    fetchData(url, options = {}) {
      return fetch(url, options).then((response) => response.json());
    },
    handleError(error) {
      console.error("Bir hata oluştu:", error);
    },
    toggleOrderDetails(orderNumber) {
      this.$set(this.filteredOrders[orderNumber], "isOpen", !this.filteredOrders[orderNumber].isOpen);
    },
    calculateTotals() {
      this.totalOrders = Object.keys(this.filteredOrders).length;
      this.totalSales = Object.values(this.filteredOrders).reduce((acc, order) => acc + this.calculateTotalSales(order), 0);     
      this.totalDiscounts = Object.values(this.filteredOrders).reduce((acc, order) => acc + this.calculateTotalDiscount(order), 0);
      this.totalCost = Object.values(this.filteredOrders).reduce((acc, order) => acc + this.calculateTotalCost(order), 0);
      this.totalCommissions = Object.values(this.filteredOrders).reduce((acc, order) => acc + this.calculateTotalCommission(order), 0);
      this.totalCargoFee = Object.values(this.filteredOrders).reduce((acc, order) => acc + this.calculateCargoFee(order), 0);
      this.totalPlatformFee = this.totalOrders * this.platformFee;
      this.totalVAT = Object.values(this.filteredOrders).reduce((acc, order) => acc + this.calculateVAT(order), 0);
      this.profitLoss = this.calculateTotalProfit();
    },
    calculateTotalSales(order) {
      // Her siparişin 'totalPrice' değerini toplar
      return order.totalPrice || 0;
    },
    calculateTotalCost(order) {
      return order.lines.reduce((acc, line) => acc + (line.cost || 0) * line.quantity, 0);
    },
    calculateTotalDiscount(order) {
      return order.lines.reduce((acc, line) => acc + (line.discount || 0) * line.quantity, 0);
    },
    calculateTotalCommission(order) {
      return order.lines.reduce((acc, line) => {
        const effectivePrice = line.amount - (line.discount || 0); // İndirim sonrası fiyat
        const commissionAmount = (effectivePrice * (line.comission || 0)) / 100; // Yüzdelik komisyon
        return acc + commissionAmount * line.quantity; // Miktar ile çarpılır
      }, 0);
    },
    calculateOrderCommissionRate(order) {
      const totalSales = this.calculateTotalSales(order);
      const totalCommission = this.calculateTotalCommission(order);

      // Komisyon oranını hesapla ve yüzdelik formatta döndür
      return totalSales > 0 ? ((totalCommission / totalSales) * 100).toFixed(2) : "0.00";
    },
    calculateVAT(order) {
      const vatAmount = order.lines.reduce((acc, line) => {
        const vatRate = line.vatBaseAmount || 0;
        const productNetPrice = line.amount - line.discount;
        const productVAT = (productNetPrice * vatRate) / 100;
        return acc + productVAT;
      }, 0);
      return vatAmount;
    },
    calculateCargoFee(order) {
      let totalDesi = 0; // Siparişin toplam desisi

      // Siparişteki her ürün için desi hesapla
      order.lines.forEach(line => {
        const productDesi = line.desi || 0; // Ürünün desisi (default: 0)
        totalDesi += productDesi * line.quantity; // Ürünün desisini miktarı ile çarp
      });

      // Kargo sağlayıcısını belirle (default: "Aras Kargo Marketplace")
      const provider = order.cargoProviderName || "Aras Kargo Marketplace";

      // Desi aralıklarına göre kargo ücret tablosu
      const cargoTable = {
        "Aras Kargo Marketplace": [
          47.49, 47.49, 51.07, 57.21, 62.36, 68.51, 74.64, 79.17, 84.46, 89.31,
          94.18, 98.27, 102.40, 106.50, 110.61, 114.68, 121.11, 127.51, 133.94, 140.34,
          146.76, 152.61, 158.49, 164.30, 170.18, 176.02, 181.66, 187.30, 192.95, 198.60,
          204.24, 210.37, 216.49, 222.61, 228.73, 234.85, 240.98, 247.10, 253.22, 259.34,
          265.46, 271.58, 277.70, 283.82, 289.94, 296.06, 302.18, 308.30, 314.42, 320.54,
          326.67, 332.79, 338.91, 345.03, 351.15, 357.27, 363.39, 369.51, 375.63, 381.75,
          387.87, 393.99, 400.11, 406.24, 412.36, 418.48, 424.60, 430.72, 436.84, 442.96, 
          449.08, 455.20, 461.32, 467.44, 473.56, 479.68, 485.80, 491.93, 498.05, 504.17,
          510.29, 516.41, 522.53, 528.65, 534.77, 540.89, 547.01, 553.13, 559.25, 565.37,
          571.49, 577.62, 583.74, 589.86, 595.98, 602.10, 608.22, 614.34, 620.46, 626.58, 
          632.70, 638.82, 644.94, 651.06, 657.18, 663.31, 669.43, 675.55, 681.67, 687.79, 
          693.91, 700.03, 706.15, 712.27, 718.39, 724.51, 730.63, 736.75, 742.88, 749.00, 
          755.12, 761.24, 767.36, 773.48, 779.60, 785.72, 791.84, 797.96, 804.08, 810.20, 
          816.32, 822.44, 828.57, 834.69, 840.81, 846.93, 853.05, 859.17, 865.29, 871.41, 
          877.53, 883.65, 889.77, 895.89, 902.01, 908.13, 914.26, 920.38, 926.50, 932.62, 
          938.74, 944.86, 950.98, 957.10, 963.22, 969.34, 975.46, 981.58, 987.70, 993.82, 
          999.95, 1006.07, 1012.19, 1018.31, 1024.43, 1030.55, 1036.67, 1042.79, 1048.91, 
          1055.03, 1061.15, 1067.27, 1073.39, 1079.51, 1085.64, 1091.76, 1097.88, 1104.00, 
          1110.12, 1116.24, 1122.36, 1128.48, 1134.60, 1140.72, 1146.84, 1152.96, 1159.08, 
          1165.21, 1171.33, 1177.45, 1183.57, 1189.69, 1195.81, 1201.93, 1208.05, 1214.17, 
          1220.29, 1226.41, 1232.53, 1238.65, 1244.77, 1250.90, 1257.02, 1263.14, 1269.26, 
          1275.38, 1281.50, 1287.62, 1293.74, 1299.86, 1305.98, 1312.10, 1318.22, 1324.34, 
          1330.46, 1336.59, 1342.71, 1348.83, 1354.95, 1361.07, 1367.19, 1373.31, 1379.43, 
          1385.55, 1391.67, 1397.79, 1403.91, 1410.03, 1416.15, 1422.28, 1428.40, 1434.52, 
          1440.64, 1446.76, 1452.88, 1459.00, 1465.12, 1471.24, 1477.36, 1483.48, 1489.60, 
          1495.72, 1501.85, 1507.97, 1514.09, 1520.21, 1526.33, 1532.45, 1538.57, 1544.69, 
          1550.81, 1556.93, 1563.05, 1569.17, 1575.29, 1581.41, 1587.54, 1593.66, 1599.78, 
          1605.90, 1612.02, 1618.14, 1624.26, 1630.38, 1636.50, 1642.62, 1648.74, 1654.86, 
          1660.98, 1667.10, 1673.23, 1679.35, 1685.47, 1691.59, 1697.71, 1703.83, 1709.95, 
          1716.07, 1722.19, 1728.31, 1734.43, 1740.55, 1746.67, 1752.79, 1758.92, 1765.04, 
          1771.16, 1777.28, 1783.40, 1789.52, 1795.64, 1801.76, 1807.88, 1814.00, 1820.12, 
          1826.24, 1832.36, 1838.49, 1844.61, 1850.73, 1856.85, 1862.97, 1869.09, 1875.21, 
          1881.33, 1887.45, 1893.57, 1899.69, 1905.81, 1911.93, 1918.05, 1924.18, 1930.30, 
          1936.42, 1942.54, 1948.66, 1954.78, 1960.90, 1967.02, 1973.14, 1979.26, 1985.38, 
          1991.50, 1997.62, 2003.74, 2009.87, 2015.99, 2022.11, 2028.23, 2034.35, 2040.47, 
          2046.59, 2052.71, 2058.83, 2064.95, 2071.07, 2077.19, 2083.31, 2089.43, 2095.56, 
          2101.68, 2107.80, 2113.92, 2120.04, 2126.16, 2132.28, 2138.40, 2144.52, 2150.64, 
          2156.76, 2162.88, 2169.00, 2175.13, 2181.25, 2187.37, 2193.49, 2199.61, 2205.73, 
          2211.85, 2217.97, 2224.09, 2230.21, 2236.33, 2242.45, 2248.57, 2254.69, 2260.82, 
          2266.94, 2273.06, 2279.18, 2285.30, 2291.42, 2297.54, 2303.66, 2309.78, 2315.90, 
          2322.02, 2328.14, 2334.26, 2340.38, 2346.51, 2352.63, 2358.75, 2364.87, 2370.99, 
          2377.11, 2383.23, 2389.35, 2395.47, 2401.59, 2407.71, 2413.83, 2419.95, 2426.07, 
          2432.20, 2438.32, 2444.44, 2450.56, 2456.68, 2462.80, 2468.92, 2475.04, 2481.16, 
          2487.28, 2493.40, 2499.52, 2505.64, 2511.76, 2517.89, 2524.01, 2530.13, 2536.25, 
          2542.37, 2548.49, 2554.61, 2560.73, 2566.85, 2572.97, 2579.09, 2585.21, 2591.33, 
          2597.46, 2603.58, 2609.70, 2615.82, 2621.94, 2628.06, 2634.18, 2640.30, 2646.42, 
          2652.54, 2658.66, 2664.78, 2670.90, 2677.02, 2683.15, 2689.27, 2695.39, 2701.51, 
          2707.63, 2713.75, 2719.87, 2725.99, 2732.11, 2738.23, 2744.35, 2750.47, 2756.59, 
          2762.71, 2768.84, 2774.96, 2781.08, 2787.20, 2793.32, 2799.44, 2805.56, 2811.68, 
          2817.80, 2823.92, 2830.04, 2836.16, 2842.28, 2848.40, 2854.53, 2860.65, 2866.77, 
          2872.89, 2879.01, 2885.13, 2891.25, 2897.37, 2903.49, 2909.61, 2915.73, 2921.85, 
          2927.97, 2934.10, 2940.22, 2946.34, 2952.46, 2958.58, 2964.70, 2970.82, 2976.94, 
          2983.06, 2989.18, 2995.30, 3001.42, 3007.54, 3013.66, 3019.79, 3025.91, 3032.03, 
          3038.15, 3044.27, 3050.39, 3056.51, 3062.63, 3068,75, 3074,87, 3080,99
        ],
        
        "Sürat Kargo Marketplace": [
          48.72, 48.72, 48.72, 56.41, 62.00, 68.00, 74.00, 79.00, 84.00, 89.00,
          94.00, 97.14, 101.21, 105.28, 109.34, 113.36, 119.72, 126.04, 132.40, 138.74,
          145.06, 151.60, 157.45, 163.22, 169.05, 174.87, 180.46, 186.07, 191.67, 197.29,
          202.90, 242.72, 249.78, 256.86, 263.91, 270.98, 278.03, 285.09, 292.17, 299.22,
          306.29, 313.35, 320.40, 327.48, 334.53, 341.60, 348.66, 355.71, 362.79, 369.84,
          376.91, 383.97, 391.02, 398.10, 405.15, 412.23, 419.28, 426.33, 433.41, 440.46, 
          447.54, 454.59, 461.64, 468.72, 475.77, 482.85, 489.90, 496.95, 504.03, 511.09, 
          518.16, 525.21, 532.26, 539.34, 546.40, 553.47, 560.52, 567.57, 574.65, 581.71, 
          588.78, 595.83, 602.88, 609.97, 617.02, 624.09, 631.14, 638.19, 645.28, 652.33, 
          659.40, 666.45, 673.50, 680.59, 687.64, 694.71, 701.76, 708.82, 715.90, 722.95,
          730.02, 737.55, 745.08, 752.61, 760.14, 767.67
        ]
      };

      // Toplam desiye uygun fiyatı seç (tablo dışına çıkmamak için maksimum indeks: 90)
      let feeIndex = Math.floor(totalDesi); 
      if (feeIndex > 500) feeIndex = 500; // Maksimum desi limiti

      // Seçilen sağlayıcının fiyat tablosundan ücreti al
      const providerFees = cargoTable[provider];
      if (providerFees) {
        return providerFees[feeIndex];
      } else {
        return 49.30; // Varsayılan kargo ücreti (tanımsız sağlayıcılar için)
      }
    },
    getTotalDesi(order) {
      let totalDesi = 0; // Siparişin toplam desisi

      // Siparişteki her ürün için desi hesapla
      order.lines.forEach(line => {
        const productDesi = line.desi || 0; // Ürünün desisi
        totalDesi += productDesi * line.quantity; // Ürünün desisini miktarı ile çarp
      });

      return totalDesi.toFixed(2); // Desi'yi iki ondalıklı göster
    },
    calculateTotalCargoFee() {
      return Object.values(this.orders).reduce((total, order) => {
        return total + this.calculateCargoFee(order); // Tüm siparişlerin kargo ücretini hesapla
      }, 0).toFixed(2);
    },
    calculateTotalProfit() {
      return Object.values(this.filteredOrders).reduce(
        (totalProfit, order) => totalProfit + parseFloat(this.calculateProfitLossPerOrder(order)),
        0
      ).toFixed(2); // Sonucu 2 ondalık basamağa yuvarla
    },
    calculateTotalProfitNoVAT() {
      return Object.values(this.filteredOrders).reduce(
        (totalProfit, order) => totalProfit + parseFloat(this.calculateProfitLossPerOrderNoVAT(order)),
        0
      ).toFixed(2); // Sonucu 2 ondalık basamağa yuvarla
    },
    calculateProfitLossPerOrder(order) {
      const totalSales = this.calculateTotalSales(order); // Siparişin toplam satış tutarı
      const totalCost = this.calculateTotalCost(order); // Siparişin toplam maliyeti
      const totalCommission = this.calculateTotalCommission(order); // Siparişin toplam komisyonu
      const shippingFee = this.calculateCargoFee(order); // Siparişin kargo ücreti
      const platformFee = this.platformFee; // Tek sipariş için platform ücreti
      const vat = this.calculateVAT(order); // Siparişin toplam KDV'si
      const totalDiscount = this.calculateTotalDiscount(order); // Siparişin toplam indirimi

      // Kar/Zarar Hesabı
      const profitOrLoss = totalSales - (totalCost + totalCommission + shippingFee + platformFee + vat);

      // Sonucu 2 ondalık basamağa yuvarla
      return profitOrLoss
    },
    calculateProfitLossPerOrderNoVAT(order) {
      const totalSales = this.calculateTotalSales(order); // Siparişin toplam satış tutarı
      const totalCost = this.calculateTotalCost(order); // Siparişin toplam maliyeti
      const totalCommission = this.calculateTotalCommission(order); // Siparişin toplam komisyonu
      const shippingFee = this.calculateCargoFee(order); // Siparişin kargo ücreti
      const platformFee = this.platformFee; // Tek sipariş için platform ücreti
      const vat = this.calculateVAT(order); // Siparişin toplam KDV'si
      const totalDiscount = this.calculateTotalDiscount(order); // Siparişin toplam indirimi

      // Kar/Zarar Hesabı
      const profitOrLoss = totalSales - (totalCost + totalCommission + shippingFee + platformFee);

      // Sonucu 2 ondalık basamağa yuvarla
      return profitOrLoss
    },
    addPlatformFeeToOrders() {
      Object.keys(this.orders).forEach((orderNumber) => {
        this.orders[orderNumber].platformFee = this.platformFee;
      });
    },
    matchCostComissionToOrder() {
      Object.keys(this.orders).forEach((orderNumber) => {
        const order = this.orders[orderNumber];
        order.lines.forEach((line) => {
          const costComission = this.costComissionData.find((item) => item.barcode === line.barcode);
          if (costComission) {
            line.cost = costComission.cost;
            line.comission = costComission.comission;
            line.desi = costComission.desi;
          }
        });
      });
    },
    formatDate(date) {
      const options = {
        timeZone: "Europe/Istanbul",
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };

      // Tarih nesnesi oluşturuluyor
      const dateObj = new Date(date);

      // 3 saat geri alma (milisaniye cinsinden)
      dateObj.setHours(dateObj.getHours() - 3);

      // Formatlanmış tarih döndürülüyor
      return dateObj.toLocaleString("tr-TR", options);
    },
  },
};
</script>

<style scoped>
.order-summary {
  margin-bottom: 20px;
}

.summary-table {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 12px;
}

.summary-table th, .summary-table td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}

.summary-table th {
  background-color: whitesmoke;
}

.summary-table td {
  font-size: 14px;
}

.order-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.order-item {
  flex: 1 1 100%;
  max-width: 100%;
  margin-bottom: 8px;
}

.order-header {
  background-color: whitesmoke;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-header h4 {
  font-weight: bold;
  font-size: 14px;
  margin: 0;
}

button {
  padding: 11px;
  border-radius: 5px;
  margin-left: 8px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 12px;
  width: 15%;
}

button:hover {
  background-color: #45a049;
}

.order-details {
  display: flex;
}

.left-side {
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 48%;
  background-color: whitesmoke;
  border-radius: 8px;
  padding: 8px;
}

.right-side {
  width: 99%;
  background-color: whitesmoke;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 8px;
  border-radius: 8px;
  padding: 8px;
}

.order-details p {
  margin: 5px 0;
  font-size: 13px;
}

h5 {
  font-size: 13px;
  margin-top: 1px;
}

ul {
  padding: 0;
  list-style: none;
}

li {
  margin-bottom: 1px;
}

/* Tarih filtresi bölümüne stil ekleyelim */
.filter-section {
  background-color: whitesmoke;
  padding: 15px;
  margin-bottom: 12px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.date-filters {
  align-items: center;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.date-filters label {
  font-size: 1.1rem;
  color: #333;
}

.date-filters input {
  padding: 8px 12px;
  font-size: 1rem;
  border: 3px solid #9d9d9d;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease;
}

.date-filters input:focus {
  border-color: #007bff;
}

/* Kazanç için yeşil arka plan */
.profit {
  background-color: #d4edda; /* Yeşil arka plan */
  color: #155724; /* Yeşil yazı rengi */
  padding: 10px;
  border-radius: 5px;
  flex: 1;
  max-width: 100%;
  margin-right: 8px;
}

/* Zarar için kırmızı arka plan */
.loss {
  background-color: #f8d7da; /* Kırmızı arka plan */
  color: #721c24; /* Kırmızı yazı rengi */
  padding: 10px;
  border-radius: 5px;
  flex: 1;
  max-width: 100%;
}

.most-sold-products {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: whitesmoke;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
}
.most-sold-products table {
  width: 100%;
  border-collapse: collapse;
}
.most-sold-products th, .most-sold-products td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
.most-sold-products th {
  background-color: whitesmoke;
  font-weight: bold;
}
.pagination {
  display: flex;
  gap: 5px;
  margin-top: 10px;
}
.pagination button {
  padding: 5px 10px;
  border: 1px solid #ddd;
  background-color: rgb(136, 136, 136);
  cursor: pointer;
}
.pagination button.active {
  background-color: #45a049;
  color: white;
  border-color: whitesmoke;
}

.table-container {
  width: 100%;
  overflow-x: auto; /* Mobilde yatay kaydırma desteği */
  border-radius: 8px;
}

.modern-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.header {
  margin-bottom: 8px;
}

</style>